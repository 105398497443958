<template>
  <loading-dialog :loading="$apollo.loading">
    <v-expansion-panels v-model="panel" focusable multiple>
      <!-- config -->
      <processing-config
        :integration-type="integration_type"
        :is-deploying="status_account"
        :account-id="accountId"
        :processing-config="config"
        @cancel="cancelAndRestoreData"
      />
      <!-- dates -->
      <processing-dates :is-deploying="status_account" :account-id="accountId" :date-formats="date_formats" @cancel="cancelAndRestoreData" />
      <!-- hours -->
      <processing-hours :is-deploying="status_account" :account-id="accountId" :hour-formats="hour_formats" @cancel="cancelAndRestoreData" />
    </v-expansion-panels>
  </loading-dialog>
</template>

<script>
import { QUERY_GET_ACCOUNT } from '@/modules/accounts/graphql'

export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog'),
    ProcessingConfig: () => import('@/modules/accounts/components/edit/ProcessingConfig'),
    ProcessingDates: () => import('@/modules/accounts/components/edit/ProcessingDates'),
    ProcessingHours: () => import('@/modules/accounts/components/edit/ProcessingHours')
  },
  data: () => ({
    accountId: '',
    config: {
      unified: '',
      bloated: '',
      version: '',
      encoding: ''
    },
    date_formats: {},
    hour_formats: {},
    panel: [0],
    status_account: false,
    integration_type: ''
  }),
  apollo: {
    adminAccount: {
      query: QUERY_GET_ACCOUNT,
      fetchPolicy: 'network-only',
      variables() {
        return { _id: this.accountId }
      },
      skip() {
        return !this.accountId
      },
      update({ adminAccount }) {
        if (!adminAccount) return {}

        this.config.unified = adminAccount?.processing_configuration.unified
        this.config.bloated = adminAccount?.processing_configuration.bloated
        this.config.version = adminAccount?.processing_configuration.version
        this.config.encoding = adminAccount?.processing_configuration.encoding
        this.date_formats = adminAccount?.processing_configuration.date_formats
        this.hour_formats = adminAccount?.processing_configuration.hour_formats
        this.status_account = adminAccount?.status_account?.deploying || adminAccount?.status_account?.test
        this.integration_type = adminAccount?.integration_type
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    cancelAndRestoreData() {
      this.$apollo.queries.adminAccount.refresh()
    }
  }
}
</script>
